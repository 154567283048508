@import "fonts";
//Home

.homeBody{
  position: relative;
  margin-bottom: 15px;

  .homeThumb{
    width:66px;
  }

  .homeThumbText{
    font-family:$mjh;
    font-size:15px;
    a{
      text-decoration: none;
      color: black;
    }
    @media screen and (max-width: 768px) {
      font-size:13px;
    }
  }

  .homeThumbTextSmall{
    position: absolute;
    bottom: 0;
    font-family:$mjh;
    font-size:13px;

    a{
      text-decoration: none;
      color: #4A4A4A;
    }
    @media screen and (max-width: 768px) {
      position: relative;
      padding-top: 10px;
      font-size:12px;
    }

    &::before{
      content: '';
      display: inline-block;
      width:11px;
      height:11px;
      background-color:#EB8C04;
      border-radius: 10px;
    }
  }
}

.homeMember{
  background-size: cover;
  background-position: center;
  padding: 0;
  height:300px;
  z-index: 2;
  margin-top:2px;
  text-align: center;

  @media screen and (max-width: 1200px) {
    height:300px *0.828;
  }

  @media screen and (max-width: 994px) {
    height:300px *0.64;
  }

  @media screen and (max-width: 768px) {
    height:162px;
  }

  .homeJoinBtn{
    position: relative;
    top:40%;
    width: 280px;
    height: 80px;
    border: 2px solid white;
    text-align: center;
    padding: 21px;
    font-family: $mjh;
    font-size:24px;
    color: white;
    cursor: pointer;
    margin: auto;
    @media screen and (max-width: 1200px) {
      width: 280px *0.828;
      height: 80px *0.828;
      padding: 21px *0.828;
      font-size:24px *0.828;
    }

    @media screen and (max-width: 994px) {
      width: 140px;
      height: 40px;
      text-align: center;
      padding: 10px;
      font-size:13px;
    }

    @media screen and (max-width: 768px) {
      width: 140px;
      height: 40px;
      text-align: center;
      padding: 10px;
      font-size:13px;
    }
  }

  .homeJoinBtn:hover{
    background-color: white;
    color:black;
  }
}

.homeArrowLeft{
  position: absolute;
  left:20px;
  top:45%;
  width:23px;
  z-index: 99999;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    top:50%;
    width:15px;
  }
}

.homeArrowRight{
  position: absolute;
  right:20px;
  top:45%;
  width:23px;
  z-index: 99999;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    top:50%;
    width:15px;
  }
}

.carousel-indicators{
  li{
    border:none;
    width:12px;
    height:12px;
    background-color: rgba(255,255,255,0.6);
  }

  .active{
    margin: 1px;
    border: none;
  }
}

.slideThumb{
  position: absolute;
  top:70%;
  background-image: url(../images/homeSliderThumb_bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  width:140px;
  height:172px;
  text-align: center;
  display: none;

  img{
    padding-top:10px;
    width:116px;
  }

}

.vt-special{
  padding: 0;
  margin: 0;
  margin-bottom: 40px;
  width: 100%;
  background-color: #F5F5F5;

  .vt-specialCont{
    padding: 40px 0;
  }

  .specialCenter{
    text-align: center;
  }

  .specialCont{
    .specialLabel{
      span{
        position: relative;
        top: 5px;
        background-color: #EB8C04;
        color: white;
        padding: 5px;
        font-size: 16px;
      }

    }

    .specialTitle{
      padding-top: 20px;
      font-size:42px;
      color:black;
    }

    .specialText{
      padding-top: 20px;
      font-size:14px;
      color:black;
    }
  }

  .readMore{
    width: 120px;
    height: 40px;
    border: 2px solid #000;
    text-align: center;
    padding: 10px;
    font-family: $mjh;
    font-size:13px;
    color: black;
    margin: auto;
    margin-top: 20px;
    cursor: pointer;
    a{
      text-decoration: none;
      color: black;
    }
    @media screen and (max-width: 1200px) {
      width: 120px *0.828;
      height: 40px *0.828;
      padding: 10px *0.828;
      font-size:13px *0.828;
    }

    @media screen and (max-width: 994px) {
      width: 120px;
      height: 40px;
      text-align: center;
      padding: 10px;
      font-size:13px;
    }

    @media screen and (max-width: 768px) {
      width: 120px;
      height: 40px;
      text-align: center;
      padding: 10px;
      font-size:13px;
    }
  }
  .readMore:hover{
    background-color: black;
    color:white;
    a{
      color: white;
    }
  }
}
